import React from 'react';

class Appfooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <footer className="App-footer">
                <span>
                    Проект <a href="https://nikolaywerner.ru">Николая Вернера</a>
                </span>
                <a href="https://github.com/nikolaywerner/am_alphabet">Гитхаб</a>
            </footer>
        )
    }
}

export default Appfooter;
